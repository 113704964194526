@import '/styles/variables.scss';

.imageContainer {
    width: 100%;
    height: auto;

    @include tablet {
        height: 100%;
    }
}

.videoContainer {
    max-width: 100%;
    width: 100%;
    height: 100%;

    @include laptopScaled {
        max-height: 500px;
    }
}
