@import '/styles/variables.scss';

.container797 {
    max-width: 797px;
    padding-left: 27px;
    padding-right: 27px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.container1400 {
    max-width: 1400px;
    padding-left: 27px;
    padding-right: 27px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.container1500 {
    max-width: 1500px;
    padding-left: 27px;
    padding-right: 27px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.container1500 > h2 {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    font-size: 26px;
    line-height: 36px;
    font-family: 'Formular Medium', Arial, Helvetica, sans-serif;

    @include desktop {
        font-size: 36px;
        line-height: 45px;
    }
}

.container1750 {
    max-width: 1750px;
    padding-left: 27px;
    padding-right: 27px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.containerFullwidth {
    width: 100%;
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.flexWrapFullWidth {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include tablet {
        justify-content: space-between;

        > :nth-child(n) {
            width: 45%;
        }
    }
}

.tripleColumnFullWidth {
    display: grid;
    gap: 2em 2em;
    grid-template-columns: 1fr;

    @include tablet {
        grid-template-columns: 1fr 1fr;
    }

    @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
