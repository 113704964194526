@import '/styles/variables.scss';

.header {
    margin-top: 110px;
}

.textContainer {
    margin-top: 48px;

    @include tablet {
        margin-top: 86px;
        width: 100%;
    }

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 0px;
        margin: 0;
    }

    h2 {
        text-transform: uppercase;
        font-weight: bold;
    }

    h3,
    ul,
    table,
    p {
        max-width: 800px;
    }

    h3 {
        text-transform: uppercase;
        font-weight: bold;
    }

    h4 {
        font-style: italic;
        font-weight: bold;
        display: inline;
    }

    p,
    ul {
        line-height: 30px;
    }

    ul {
        li {
            margin-bottom: 10px;
        }
    }
}

.textCenter {
    text-align: center;
}

.whiteContainer {
    background-color: white;
}

.lastUpdated {
    text-align: left;
    font-style: italic;
    padding-bottom: 40px;
}

.tableContainer {
    margin-bottom: 30px;
    .table,
    th,
    td {
        text-align: left;
        border: 1px solid var(--primary-black);
        border-collapse: collapse;
    }
    td {
        padding: 0 5px 20px;
    }
}

.cookieBannerContainer {
    color: white;
    display: flex;
    height: 250px;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--primary-black);
    flex-direction: column;

    @include tablet {
        height: 130px;
        flex-direction: row;
        justify-content: space-between;
    }

    div {
        max-width: 629px;
        font-size: 18px;
        line-height: 27px;
    }

    a {
        color: white;
        text-decoration: underline;
    }

    .buttonGroup {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include desktop {
            flex-direction: row;
        }

        button {
            min-width: 204px;
        }

        :first-child {
            margin-bottom: 5px;
            @include desktop {
                margin-right: 24px;
            }
        }
    }
}

.blackContainer {
    position: fixed;
    background-color: var(--primary-black);
    bottom: 0;
    z-index: 1;
}
