@import '/styles/variables.scss';

.headerContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 76px;
    z-index: 1;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @include desktop {
        font-size: $text-18px-in-vw;
    }

    @include large {
        font-size: 18px;
    }
}

.headerPadding {
    padding-left: 32px;
    padding-right: 32px;

    @include desktop {
        padding-left: 5%;
        padding-right: 5%;
    }

    @include large {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.navbar {
    display: none;

    @include desktop {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 76px;
    }

    a {
        text-decoration: none;
    }
}

.navElement {
    padding-left: 0px;
    padding-right: 0px;
    text-decoration: none;
    color: var(--primary-black);

    @include desktop {
        padding-left: 26px;
        padding-right: 26px;
    }
}

.dropdownMenu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    border: none;
    font-size: $text-18px-in-vw;
    font-weight: normal;
    width: 200px;
    background: none;

    @include desktop {
        padding-left: 0;
        padding-right: 0;
        width: 148px;
    }

    @include large {
        font-size: 18px;
    }

    .dropdownNavElement {
        text-align: left;
        padding-left: 32px;
        display: flex;
        align-items: center;

        svg {
            padding-left: 16px;
        }
    }

    &:focus-visible {
        border-radius: 0;
        outline: 2px solid var(--primary-orange);
        outline-offset: -2px;
    }

    &:hover {
        display: grid;
        color: var(--primary-black-50);

        .dropdownSubmenu {
            display: grid;
        }
    }

    &:focus-within {
        .dropdownSubmenu {
            display: grid;
        }
    }
}

.solutionsDropdownMenu {
    width: 275px;

    .dropdownNavElement {
        text-align: left;
        padding-left: 0;

        svg {
            padding-left: 16px;
        }
    }
}

.resourcesDropdownMenu {
    width: 150px;

    .dropdownNavElement {
        text-align: left;
        padding-left: 0;

        svg {
            padding-left: 16px;
        }
    }
}

.dropdownMenu:hover .dropdownMenu:active .dropdownSubmenu {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.navElementDropdownOpen > svg > path,
.dropdownMenu:hover .dropdownNavElement > svg > path {
    opacity: 0.5;
    stroke-width: 2;
    transform: rotate(180deg);
}

.headerArrow {
    fill: none;
    stroke: black;
    stroke-width: 2;
    transition: 0.2s ease-in-out;
    transform-origin: center center;
}

.dropdownSubmenu {
    display: none;
    border-radius: 12px;
    position: absolute;
    top: 66px;
    left: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #2624231a;

    .dropdownSubmenuItems {
        margin: 32px 0;
        text-align: left;

        .dropdownSubmenuHeadTitle {
            margin: 0 0 10px 15px;
            color: var(--primary-black);
            font-size: 18px;
            font-weight: 600;
        }

        a {
            display: grid;
            text-decoration: none;
            margin: 0 32px;

            &:nth-child(n) {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.offeringsDropdownSubmenu {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    min-width: 552px;
    text-align: left;
}

.companyDropdownSubmenu {
    min-width: 224px;
}

.joinUs {
    padding-left: 0px;

    @include desktop {
        padding-left: 26px;
        padding-right: 0px;
    }
}

.logo:hover {
    opacity: 1;
}

.hamburgerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: none;
    padding: 0;
    z-index: 10;

    span {
        width: 33px;
        height: 2px;
        background: black;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        position: relative;
        transform-origin: 5px center;
    }
    &Open {
        :first-child {
            transform: rotate(45deg);
        }
        :last-child {
            transform: rotate(-45deg);
        }
    }
    &:focus-visible {
        border-radius: 0;
        outline: 2px solid var(--primary-orange);
        outline-offset: -2px;
    }
    @include desktop {
        display: none;
    }
}

.hamburgerMenu {
    display: none;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    animation: fadeIn 0.5s ease-out;

    &Open {
        display: block;
        opacity: 1;
        position: absolute;
        background-color: #fff;
        display: block;
        top: 76px;
        left: 0;
        right: 0;
        padding: 0 0 150px 25px;
        z-index: -1;
        max-height: 80vh;
        overflow-y: auto;
    }

    .navElement {
        font-size: 26px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        border: none;
        border-radius: 0;

        &Dropdown {
            display: flex;
            gap: 16px;
            align-items: center;
        }

        &Submenu {
            display: none;
            width: auto;

            &Open {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-left: 15px;

                a {
                    display: block;
                    margin: 25px 0 0 10px;
                    text-decoration: none;
                    font-size: 120%;
                }
            }

            &Items {
            }

            &HeadTitle {
                color: var(--primary-black);
                font-size: 120%;
                font-weight: 600;
                margin-top: 25px;
            }
        }

        &:focus-visible {
            border-radius: 0;
            outline: 2px solid var(--primary-orange);
            outline-offset: -2px;
        }
    }

    a,
    button {
        margin-top: 45px;
    }
}

.background {
    background-color: white;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}

.noBackground {
    background: none;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}

.bgNoFade {
    background-color: white;
}

.noBgNoFade {
    background: none;
}

@keyframes fadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
