@import '/styles/variables.scss';

.footer {
    color: white;
    font-size: 18px;
    background-color: var(--primary-black);

    a {
        color: white;
        text-decoration: none;
    }
}

.location {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 68px;

    background-color: var(--primary-black);

    .state {
        font-family: 'Formular Medium', Arial, Helvetica, sans-serif;
    }

    @include tablet {
        grid-template-columns: 1fr 1fr;
        padding-bottom: 40px;
    }

    @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding-bottom: 63px;
    }

    ul:nth-child(n + 2) {
        /* Skips the first entry */
        padding-top: 63px;

        @include tablet {
            padding-top: 0;
        }
    }

    ul:nth-child(n + 3) {
        @include tablet {
            /* Skips first two entries */
            padding-top: 40px;
        }

        @include desktop {
            /* Skips first two entries */
            padding-top: 0px;
        }
    }

    ul > :first-child {
        padding-bottom: 10px;
    }

    .leftAlignedList {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }
}

.socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #8c8c8c;
    padding-top: 39px;

    @include desktop {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
        height: 55px;
        padding-top: 16px;
        align-content: center;
    }

    ul {
        padding-left: 0;
        > li {
            display: inline-block;
            margin-left: 0;
            > a {
                color: #8c8c8c;
                margin-right: 12px;
                text-indent: -9999px;
                overflow: hidden;
                width: 40px;
                height: 40px;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: 100% auto;
                margin-bottom: -15px;

                &.facebook {
                    background-image: url('/images/socials/facebook-white.svg');
                }

                &.instagram {
                    background-image: url('/images/socials/instagram-white.svg');
                }

                &.linkedin {
                    background-image: url('/images/socials/linkedin-white.svg');
                }
            }
        }
    }

    > :last-child {
        font-size: 14px;
        max-width: 200px;

        @include desktop {
            max-width: 100%;
        }

        a {
            text-decoration: underline;
        }
    }
}

.copy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 20px;
    padding-bottom: 70px;

    p {
        margin: 0;
        color: #c3c3c5;
    }

    a {
        color: #c3c3c5;
        text-decoration: underline;
    }
}
