@import '/styles/variables.scss';

.ingressContainer {
    padding-top: 110px;

    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .ingress {
        font-size: 36px;
        line-height: 47px;
        margin: 0 0;
        font-family: 'Formular Medium', Arial, Helvetica, sans-serif;

        @include desktop {
            font-size: 56px;
            line-height: 66px;
            margin-bottom: 20px;
            max-width: 1000px;
        }

        h1 {
            font-size: 36px;
            line-height: 47px;
            margin: 0 0;
            font-family: 'Formular Medium', Arial, Helvetica, sans-serif;

            @include desktop {
                font-size: 56px;
                line-height: 66px;
                margin-bottom: 0;
            }
        }
    }

    .boxright {
        @include desktop {
            align-self: flex-end;
        }
    }

    .quote {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 32px;
        max-width: 327px;

        @include tablet {
            max-width: 500px;
        }

        @include desktop {
            font-size: 18px;
            max-width: 644px;
        }

        @include large {
            font-size: 22px;
        }
    }

    .button {
        margin-bottom: 140px;
        background-color: transparent;
        color: black;
        border: 2px solid var(--primary-black);
        text-decoration: none;
        width: 180px;
        padding: 5px 20px;
        font-weight: normal;
        font-size: 18px;

        &:hover {
            color: white;
            background-color: var(--primary-black);
            -webkit-transition: background-color 250ms linear;
            -ms-transition: background-color 250ms linear;
            transition: background-color 250ms linear;
        }

        &:active {
            opacity: 0.9;
        }
    }

    .hidden {
        display: none;

        @include desktop {
            display: block;
        }
    }
}
