$phone: 320px;
$large-phone: 500px;
$tablet-width: 768px;
$desktop-width: 1150px;
$large-width: 1400px;

// Variables used elsewhere
$max-text-width: 556px;
// font sizes
$text-181px-in-vw: 1vw;
$text-18px-in-vw: 1.2vw;
$text-22px-in-vw: 1.7vw;
$text-36px-in-vw: 2.4vw;
$text-44px-in-vw: 2.7vw;
$text-56px-in-vw: 3.7vw;
$text-64px-in-vw: 4vw;

@mixin phone {
    @media (min-width: #{$phone}) {
        @content;
    }
}

@mixin largePhone {
    @media (min-width: #{$large-phone}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$large-width}) {
        @content;
    }
}

@mixin laptopScaled {
    @media (max-width: 1280px) and (max-height: 720px) {
        @content;
    }
}
