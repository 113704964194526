@import '/styles/variables.scss';

.homepage {
    position: relative;
}

.readMoreContainer {
    @include desktop {
        display: flex;
        justify-content: flex-end;
    }

    .readMore {
        margin-bottom: 80px;

        @include tablet {
            margin-right: 173px;
        }
    }
}

.punchline {
    font-size: 26px;
    font-weight: 500;
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 787px;
    background-repeat: no-repeat;
    background-image: url('/images/backgrounds/home-circle-background.svg');
    background-position: left;
    background-position-x: -100px;
    background-size: 450px;

    @include tablet {
        padding-top: 100px;
    }

    @include desktop {
        font-size: $text-44px-in-vw;
        background-position-x: center;
        background-position-y: 20px;
        padding-top: 150px;
        padding-bottom: 130px;
        padding-left: 42%;
    }

    @include large {
        font-size: 44px;
    }
}

.subHeader {
    text-transform: none;
    color: var(--primary-black);
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 40px;

    @include desktop {
        font-size: $text-36px-in-vw;
        line-height: 45px;
        margin-bottom: 48px;
    }

    @include large {
        font-size: 36px;
    }
}

.link {
    text-align: center;
    text-decoration-color: var(--primary-grey);

    &:hover {
        color: var(--primary-black);
        opacity: 1;
        text-decoration-color: var(--primary-black);
    }
    @include desktop {
        font-size: $text-22px-in-vw;
    }

    @include large {
        font-size: 22px;
    }
}

.workLink {
    padding-bottom: 100px;

    @include tablet {
        text-align: center;
    }
}

.resourcesLink {
    padding-bottom: 100px;

    @include tablet {
        text-align: center;
    }
}

.centerAligned {
    display: flex;
    justify-content: center;
}

.backgroundVideoContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: fill;
    z-index: -1;

    .backgroundVideo {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.links {
    margin: 30px 0;
    display: flex;

    a {
        &:first-child {
            margin-right: 16px;
        }
    }
}
